import React from 'react'
import styles from './LoadingScreen.module.css';

export default function LoadingScreen() {
  const loadingItems = Array.from({ length: 3 });
  const loadingFilter = Array.from({ length: 4 });

  return (
    <div className={styles.container}>

      <div className={styles.left}>
        {loadingFilter.map((_, index) => (
          <div key={index} className={styles.filter_1}></div>
        ))}
      </div>

      <div className={styles.right}>
        <div className={styles.search}></div>
        {loadingItems.map((_, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.line1}></div>
            <div className={styles.line2}></div>
            <div className={styles.line3}></div>
          </div>
        ))}
        {loadingItems.map((_, index) => (
          <div key={index} className={styles.card}>
            <div className={styles.image}></div>
          </div>
        ))}
      </div>

    </div>
  )
}